<template>
  <v-app>
    <v-content>
      <v-row align="center" justify="center">
        <router-view />
      </v-row>
    </v-content>
    <v-footer class="pa-3 d-print-none mt-4">
      <v-spacer />
      <div class="footer">
        <a href="https://www.barmherzige-muenchen.de/" target="_blank"
          >Barmherzige Brüder München</a
        >
        |
        <a href="https://www.barmherzige-muenchen.de/info/impressum.html" target="_blank"
          >Impressum</a
        >
        |
        <a :href="`/datenschutz_${$i18n.locale}.html`" target="_blank">Datenschutz</a>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: (vm) => ({}),

  methods: {},

  mounted() {},
};
</script>

<style scoped>
.footer a {
  color: #1976d2;
  text-decoration: none;
}
</style>
